import PropTypes from 'prop-types';
import { Paper } from '@packages/ui';

function Card({ children, className }) {
  return <Paper className={className}>{children}</Paper>;
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Card;
