const LAST_REFRESH_AT_KEY = `${window.location.origin}_lastRefreshAt`;
const SEC_THRESHOLD = 20;

function getLastRefreshedAt() {
  return sessionStorage.getItem(LAST_REFRESH_AT_KEY);
}

function setLastRefreshedAt() {
  sessionStorage.setItem(LAST_REFRESH_AT_KEY, Date.now());
}

function diffTimestampsInSeconds(time1, time2) {
  const diff = time1 - time2;
  const diffInSec = diff / 1000;

  return Math.abs(diffInSec);
}

/**
 * Handle ChunkLoadError by reloading the app when error is encountered.
 * Avoiding reload loop by storing a timestamp in the Session Storage.
 *
 * @returns
 * {boolean} An indication if the page is being reloaded
 */
export function onChunkLoadError() {
  let isReloading = false;

  // Get the last time a refresh was made
  const lastRefreshedAt = getLastRefreshedAt();

  // If the page was not refreshed, then we refresh and set a refresh value
  if (!lastRefreshedAt) {
    isReloading = true;

    setLastRefreshedAt();
    window.location.reload();
  }

  // If the time passed between the last refresh and now is greater than the threshold,
  // then we set a new refresh value and refresh the page
  if (diffTimestampsInSeconds(lastRefreshedAt, Date.now()) > SEC_THRESHOLD) {
    isReloading = true;

    setLastRefreshedAt();
    window.location.reload();
  }

  return isReloading;
}
