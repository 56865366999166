import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import MenuItemBase from '../MenuItemBase/MenuItemBase';

const Option = forwardRef(
  ({ children, className, disabled, onClick, value }, ref) => (
    <MenuItemBase
      ref={ref}
      className={className}
      disabled={disabled}
      onClick={onClick}
      value={value}
    >
      {children}
    </MenuItemBase>
  ),
);

Option.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default Option;
