import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const keyframesCss = css`
  @keyframes circle1-translate {
    0% {
      transform: translate(10px, 33.528168px);
    }
    6.666667% {
      transform: translate(10px, 41.764084px);
    }
    13.333333% {
      transform: translate(10px, 33.528168px);
    }
    26.666667% {
      transform: translate(10px, -2.651608px);
    }
    33.333333% {
      transform: translate(10px, -4.471832px);
    }
    40% {
      transform: translate(10px, -2.651608px);
    }
    53.333333% {
      transform: translate(10px, 33.528168px);
    }
    60% {
      transform: translate(10px, 41.764084px);
    }
    66.666667% {
      transform: translate(10px, 33.528168px);
    }
    100% {
      transform: translate(10px, 33.528168px);
    }
  }

  @keyframes circle1-scale {
    0% {
      transform: scale(1, 1);
    }
    6.666667% {
      transform: scale(1.5, 0.5);
    }
    13.333333% {
      transform: scale(1, 1);
    }
    26.666667% {
      transform: scale(1, 1);
    }
    33.333333% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    53.333333% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1.5, 0.5);
    }
    66.666667% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes circle2-translate {
    0% {
      transform: translate(30px, 33.528168px);
    }
    13.333333% {
      transform: translate(30px, 33.528168px);
    }
    20% {
      transform: translate(30px, 41.764084px);
    }
    26.666667% {
      transform: translate(30px, 33.528168px);
    }
    40% {
      transform: translate(30px, -2.651608px);
    }
    46.666667% {
      transform: translate(30px, -4.471832px);
    }
    53.333333% {
      transform: translate(30px, -2.651608px);
    }
    66.666667% {
      transform: translate(30px, 33.528168px);
    }
    73.333333% {
      transform: translate(30px, 41.764084px);
    }
    80% {
      transform: translate(30px, 33.528168px);
    }
    100% {
      transform: translate(30px, 33.528168px);
    }
  }

  @keyframes circle2-scale {
    0% {
      transform: scale(1, 1);
    }
    13.333333% {
      transform: scale(1, 1);
    }
    20% {
      transform: scale(1.5, 0.5);
    }
    26.666667% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    46.666667% {
      transform: scale(1, 1);
    }
    53.333333% {
      transform: scale(1, 1);
    }
    66.666667% {
      transform: scale(1, 1);
    }
    73.333333% {
      transform: scale(1.5, 0.5);
    }
    80% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes circle3-translate {
    0% {
      transform: translate(50px, 33.528168px);
    }
    26.666667% {
      transform: translate(50px, 33.528168px);
    }
    33.333333% {
      transform: translate(50px, 41.764084px);
    }
    40% {
      transform: translate(50px, 33.528168px);
    }
    53.333333% {
      transform: translate(50px, -2.651608px);
    }
    60% {
      transform: translate(50px, -4.471832px);
    }
    66.666667% {
      transform: translate(50px, -2.651608px);
    }
    80% {
      transform: translate(50px, 33.528168px);
    }
    86.666667% {
      transform: translate(50px, 41.764084px);
    }
    93.333333% {
      transform: translate(50px, 33.528168px);
    }
    100% {
      transform: translate(50px, 33.528168px);
    }
  }

  @keyframes circle3-scale {
    0% {
      transform: scale(1, 1);
    }
    26.666667% {
      transform: scale(1, 1);
    }
    33.333333% {
      transform: scale(1.5, 0.5);
    }
    40% {
      transform: scale(1, 1);
    }
    53.333333% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    66.666667% {
      transform: scale(1, 1);
    }
    80% {
      transform: scale(1, 1);
    }
    86.666667% {
      transform: scale(1.5, 0.5);
    }
    93.333333% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

const sizeCss = {
  sm: css`
    width: 14px;
  `,
  lg: css`
    width: 36px;
  `,
};

const loaderCss = css`
  .circle1--translate {
    animation: circle1-translate 1s linear infinite normal forwards;
  }

  .circle1--scale {
    animation: circle1-scale 1s linear infinite normal forwards;
  }

  .circle2--translate {
    animation: circle2-translate 1s linear infinite normal forwards;
  }

  .circle2--scale {
    animation: circle2-scale 1s linear infinite normal forwards;
  }

  .circle3--translate {
    animation: circle3-translate 1s linear infinite normal forwards;
  }

  .circle3--scale {
    animation: circle3-scale 1s linear infinite normal forwards;
  }
`;

function Loader({ className, size = 'lg' }) {
  return (
    <svg
      className={className}
      css={[keyframesCss, loaderCss, sizeCss[size]]}
      viewBox="0 0 60 51"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <g className="circle1--translate" transform="translate(10,33.528168)">
        <g className="circle1--scale">
          <circle r="6" transform="translate(0,10.471832)" />
        </g>
      </g>
      <g className="circle2--translate" transform="translate(30,33.528168)">
        <g className="circle2--scale">
          <circle r="6" transform="translate(0,10.471832)" />
        </g>
      </g>
      <g className="circle3--translate" transform="translate(50,33.528168)">
        <g className="circle3--scale">
          <circle r="6" transform="translate(0,10.471832)" />
        </g>
      </g>
    </svg>
  );
}

Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
};

export default Loader;
