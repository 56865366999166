import PropTypes from 'prop-types';
import MuiTableBody from '@material-ui/core/TableBody';

function TableBody({ children, ...restProps }) {
  return (
    <MuiTableBody component="div" {...restProps}>
      {children}
    </MuiTableBody>
  );
}

TableBody.propTypes = {
  children: PropTypes.node,
};

export default TableBody;
