import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import { css } from '@emotion/react';
import { elevations } from '@packages/ui';

import { colors } from '../../styles/helpers';

const trCss = css`
  min-height: 80px;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${colors.gray300};
  box-shadow: ${elevations.elevation100};
  &:hover > div,
  &:focus > div {
    background-color: ${colors.gray300};
  }
`;

function Tr({ children, ...restProps }) {
  return (
    <TableRow component="div" css={trCss} {...restProps}>
      {children}
    </TableRow>
  );
}

Tr.propTypes = {
  children: PropTypes.node,
};

export default Tr;
