import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { colors, fontSize } from '../../styles/helpers';
import Text from '../Text/Text';

const hintCss = (props) => css`
  margin-top: 6px;
  font-size: ${fontSize.xs};

  ${props.isError &&
  css`
    color: ${colors.danger};
  `};
`;

function Hint({ children, isError }) {
  return <Text css={hintCss({ isError })}>{children}</Text>;
}

Hint.propTypes = {
  children: PropTypes.node,
  isError: PropTypes.bool,
};

export default Hint;
