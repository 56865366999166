import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import MuiTooltip from '@material-ui/core/Tooltip';
import Popper from '@material-ui/core/Popper';

const popperCss = css`
  .MuiTooltip-tooltip {
    font-size: 14px;
    background-color: #14263d;
    color: #fff;
    font-weight: 400;
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  }

  .MuiTooltip-arrow {
    color: #14263d;
  }
`;

function CustomPopper(props) {
  return <Popper css={popperCss} {...props} />;
}

function Tooltip({ children, title }) {
  return (
    <MuiTooltip
      arrow
      placement="top"
      PopperComponent={CustomPopper}
      title={title}
    >
      {children}
    </MuiTooltip>
  );
}

Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
};

export default Tooltip;
