import PropTypes from 'prop-types';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { reporter } from '@packages/reporter';
import { onChunkLoadError } from './chunkLoadErrorService';

function ChunkLoadErrorHandler({
  FallbackComponent,
  loaderComponent,
  ...restProps
}) {
  const { error } = restProps;

  const isChunkLoadError = error.name === 'ChunkLoadError';

  // ChunkLoadErrors will cause a page reload, during that time, we show nothing
  if (isChunkLoadError) {
    return loaderComponent ?? null;
  }

  return <FallbackComponent {...restProps} />;
}

ChunkLoadErrorHandler.propTypes = {
  FallbackComponent: PropTypes.elementType,
  loaderComponent: PropTypes.elementType,
};

function ErrorBoundary({ children, FallbackComponent, loaderComponent }) {
  function onError(error, componentStack) {
    const isChunkLoadError = error.name === 'ChunkLoadError';

    if (isChunkLoadError) {
      const isReloading = onChunkLoadError();

      if (!isReloading) {
        reporter.error(error, { componentStack });
      }
    } else {
      reporter.error(error, { componentStack });
    }
  }

  return (
    <ReactErrorBoundary
      FallbackComponent={(
        FallbackComponentProps, // error, componentStack
      ) => (
        <ChunkLoadErrorHandler
          FallbackComponent={FallbackComponent}
          loaderComponent={loaderComponent}
          {...FallbackComponentProps}
        />
      )}
      onError={onError}
    >
      {children}
    </ReactErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  /** Rendered component in case of an error event */
  FallbackComponent: PropTypes.elementType,
  /** A loader component that appears when reloading the page on specific errors */
  loaderComponent: PropTypes.node,
};

export default ErrorBoundary;
