/* eslint-disable no-confusing-arrow */
import formatFromDateFns from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import subDays from 'date-fns/subDays';
import parseISO from 'date-fns/parseISO';
import formatDistanceToNowFn from 'date-fns/formatDistanceToNow';
import isString from 'lodash/isString';
import isAfterFromDateFns from 'date-fns/isAfter';
import he from 'date-fns/locale/he';
import enUS from 'date-fns/locale/en-US';
import differenceInDays from 'date-fns/differenceInDays';

const LOCALES_MAP = {
  en: enUS,
  he,
};

const format = (date, pattern, options = {}) =>
  formatFromDateFns(date, pattern, {
    awareOfUnicodeTokens: true,
    ...options,
    ...(options.locale && { locale: LOCALES_MAP[options.locale] }),
  });

export const tableDateFormat = 'MMM do yyyy';
export const dateFormat = 'dd-MMM-yyyy';
export const timeFormat = 'h:mm a';
export const timeFormat24H = 'HH:mm';
export const yearFirstDateTimeFormat24hours = 'yyyy-MM-dd | HH:mm:ss';
export const dateTimeFormatWithPipAndSeconds = 'dd.MM.yyyy | HH:mm:ss';
export const rehealthyFilterDateFormat = 'LLL do H:mm';
export const behealthyBackofficeDateTimeFormat = 'd MMM yyyy | HH:mm';
const healthyPortalDateFormat = 'yyyy/MM/dd';

export const preParse = (date) => (isString(date) ? parseISO(date) : date);

const stringToInt = (timestamp) =>
  isString(timestamp) ? Number.parseInt(timestamp, 10) : timestamp;

export const formatDate = (date) => (date ? format(date, dateFormat) : '');
export const formatTime = (date) =>
  date ? format(preParse(date), timeFormat) : '';

export const formatTime24H = (date) =>
  date ? format(preParse(date), timeFormat24H) : '';

export const monthFirstDateFormat = 'MMM d yyyy';
export const materialTableDateFormat = 'd MMM yyyy';
export const materialTableDateFormatWithTime24h = 'd MMM yyyy | HH:mm ';
export const invertedDateFormatWithTime = 'yyyy/MM/dd | HH:mm ';
export const dateFormatPipeWithTime = 'dd.MM.yyyy | hh:mm ';
export const dateFormatPipeWithTime24h = 'dd.MM.yyyy | HH:mm ';
export const invertedDateWithPipeAndTime24h = 'HH:mm | yyyy/MM/dd';
export const invertedDateNoTime = 'yyyy/MM/dd';
export const dateFormatNoTime = 'dd.MM.yyyy';

export const formatMonthFirstDate = (date, options) =>
  date ? format(preParse(date), monthFirstDateFormat, options) : '';

export const formatMaterialTableDate = (date, options) =>
  date ? format(preParse(date), materialTableDateFormat, options) : '';

export const formatMaterialTableDateWithTime24H = (date, options) =>
  date
    ? format(preParse(date), materialTableDateFormatWithTime24h, options)
    : '';

export const formatInvertedDateWithTime = (date, options) =>
  date ? format(preParse(date), invertedDateFormatWithTime, options) : '';

export const formatDateWithPipeAndTime = (date, options) =>
  date ? format(preParse(date), dateFormatPipeWithTime, options) : '';

export const formatDateWithPipeAndTime24h = (date, options) =>
  date ? format(preParse(date), dateFormatPipeWithTime24h, options) : '';

export const formatInvertedDateWithPipeAndTime24h = (date) =>
  /* FDA approved date format */
  date ? format(preParse(date), invertedDateWithPipeAndTime24h) : '';

export const formatInvertedDateNoTime = (date) =>
  /* FDA approved date format */
  date ? format(preParse(date), invertedDateNoTime) : '';

export const formatDateNoTime = (date) =>
  date ? format(preParse(date), dateFormatNoTime) : '';

export const formatTableDateString = (
  date,
  { twentyFour = false } = { twentyFour: false },
) => {
  if (!date) return '';
  let day;
  const isToday = isSameDay(new Date(), preParse(date));
  const isYesterday = isSameDay(preParse(date), subDays(new Date(), 1));
  /* istanbul ignore next */
  if (isToday) day = 'Today';
  else if (isYesterday) day = 'Yesterday';
  else day = format(preParse(date), tableDateFormat);
  return `${day} at ${twentyFour ? formatTime24H(date) : formatTime(date)}`;
};

export const convertMsToSeconds = (timeStamp) => timeStamp / 1000;

export const formatDateTime = (date) =>
  date ? format(stringToInt(date), yearFirstDateTimeFormat24hours) : '';

export const formatDateTimeWithPipeAndSeconds = (date) =>
  date ? format(stringToInt(date), dateTimeFormatWithPipAndSeconds) : '';

export const formatDateRehealthyFilter = (date) =>
  format(stringToInt(date), rehealthyFilterDateFormat);

export const formatDateBehealthy = (date) =>
  format(preParse(date), behealthyBackofficeDateTimeFormat);

/**
 * Format the date payload for Back End, used in Call Center
 *
 * @param {string} utcString - ISO 8601 Extended format, YYYY-MM-DDTHH:mm:ss.sssZ
 */
export const formatDateBackEndPayload = (utcString) =>
  format(preParse(utcString), healthyPortalDateFormat);

export const isAfter = (firstDate, secondDate) =>
  isAfterFromDateFns(preParse(firstDate), preParse(secondDate));

export const formatDistanceToNow = (date, options = {}) =>
  date
    ? formatDistanceToNowFn(preParse(date), {
        addSuffix: true,
        ...(options?.locale && { locale: LOCALES_MAP[options.locale] }),
      })
    : '';

export const isISODate = (text) =>
  typeof text === 'string' && /[0-9-]+T[0-9:.]+Z/.test(text);

export const getDaysSince = (date) =>
  date ? differenceInDays(new Date(), preParse(date)) : '';
