import PropTypes from 'prop-types';
import MuiTable from '@material-ui/core/Table';
import { css } from '@emotion/react';

const tableCss = css`
  &.MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 7px;
  }
`;

function Table({ children, ...restProps }) {
  return (
    <MuiTable component="div" css={tableCss} {...restProps}>
      {children}
    </MuiTable>
  );
}

Table.propTypes = {
  children: PropTypes.node,
};

export default Table;
