import { createSlice } from '@reduxjs/toolkit';

export const containerKey = 'patientList';

export const initialState = {
  perPage: 10,
  pageNumber: 1,
};

const patientListSlice = createSlice({
  name: containerKey,
  initialState,
  reducers: {
    updatePageNumber(state, { payload }) {
      state.pageNumber = payload;
    },
    updatePerPage(state, { payload }) {
      state.perPage = payload;
      state.pageNumber = 1;
    },
  },
});

export const { updatePageNumber, updatePerPage } = patientListSlice.actions;

export const { reducer } = patientListSlice;
