import { forwardRef } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import MuiMenuItem from '@material-ui/core/MenuItem';

import { colors, fontSize } from '../../styles/helpers';

const menuItemCss = css`
  font-size: ${fontSize.sm};

  :hover {
    background-color: ${colors.blueGray300};
  }
`;

const iconCss = css`
  display: inline-flex;
  margin-right: 8px;
`;

const MenuItemBase = forwardRef(
  ({ children, className, disabled, icon, onClick, value }, ref) => (
    <MuiMenuItem
      className={className}
      css={menuItemCss}
      disabled={disabled}
      ref={ref}
      onClick={onClick}
      value={value}
    >
      {icon && <span css={iconCss}>{icon}</span>}
      {children}
    </MuiMenuItem>
  ),
);

MenuItemBase.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default MenuItemBase;
