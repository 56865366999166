import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { fontSize } from '../../styles/helpers';

const textCss = (props) => css`
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${fontSize.sm};

  ${props.gutterBottom &&
  css`
    margin-bottom: 1em;
  `}
`;

function Text({
  children,
  className,
  as: Component = 'p',
  gutterBottom,
  role,
}) {
  return (
    <Component
      css={textCss({ gutterBottom })}
      className={className}
      role={role}
    >
      {children}
    </Component>
  );
}

Text.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  /* Adds a bottom margin */
  gutterBottom: PropTypes.bool,
  /* ARIA role attribute */
  role: PropTypes.string,
};

export default Text;
