import * as Sentry from '@sentry/browser';

const config = {
  isEnabled: false,
  isLogErrors: false,
};

/**
 * @see
 * [Sentry: JavaScript Configuration](https://docs.sentry.io/platforms/javascript/configuration/)
 *
 * @param {object} options
 * @param {string} options.dsn - Associated Sentry SDK. Without it, the service will not be initialized or send events
 * @param {string} [options.environment] - Associated environment. Will set an environment in Sentry
 * @param {(Array<string>|Array<RegExp>)} [options.ignoreErrors] - A list of errors to be filtered out before being sent to Sentry
 * @param {boolean} [options.isLogErrors] - Emit error reports to the console
 * @param {string} [options.version] - Application's version. Will set a release in Sentry
 */
function init({ dsn, environment, ignoreErrors, isLogErrors, version }) {
  config.isLogErrors = isLogErrors;
  config.isEnabled = Boolean(dsn);

  if (!config.isEnabled) {
    return;
  }

  Sentry.init({
    dsn,
    ignoreErrors,
    release: version,
    environment,
    attachStacktrace: true,
    /**
     * Sentry normalizes the contextual data to a given depth. Any data beyond it will be
     * trimmed and marked using its type instead ([Object] or [Array]). By default, the depth is 3.
     * It's being set to 0 in order for normalization to be disabled.
     *
     * @see
     * https://docs.sentry.io/platforms/javascript/configuration/options/#normalize-depth
     */
    normalizeDepth: 0,
  });
}

/**
 * Register a set of super properties (tags), which are included with all exceptions.
 *
 * @see
 * [Sentry: Customize Tags](https://docs.sentry.io/platforms/javascript/enriching-events/tags/)
 *
 * @param {object} tags
 */
function setTags(tags) {
  if (!config.isEnabled) {
    return;
  }

  Object.entries(tags).forEach(([key, value]) => {
    Sentry.setTag(key, value);
  });
}

/**
 * Report an error.
 *
 * @see
 * [Sentry: Capturing Errors](https://docs.sentry.io/platforms/javascript/usage/#capturing-errors)
 *
 * @param {(Error|string)} err
 * @param {object} [additionalInfo] - Additional information to be added as the report's context
 */
function error(err, additionalInfo) {
  if (!config.isEnabled) {
    return;
  }

  if (additionalInfo) {
    Sentry.setContext('Additional Information', additionalInfo);
  }

  if (err.meta) {
    Sentry.setContext("Error's Metadata", err.meta);
  }

  if (config.isLogErrors) {
    console.error(err, additionalInfo);
  }

  Sentry.captureException(err);
}

function warning(warningText, additionalInfo) {
  if (!config.isEnabled) {
    return;
  }

  Sentry.withScope((scope) => {
    scope.setLevel('warning');
    if (additionalInfo) {
      Sentry.setContext('Additional Information', additionalInfo);
    }
    Sentry.captureMessage(warningText);
  });
}

const reporter = { init, setTags, warning, error };

export { reporter };
