import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import InfoOutlinedIcon from '../icons/InfoOutlinedIcon';
import Tooltip from '../Tooltip/Tooltip';
import { colors, fontSize, spacing } from '../../styles/helpers';

const labelCss = (props) => css`
  display: block;
  margin-bottom: 12px;
  font-size: ${fontSize.xs};
  color: ${colors.gray700};

  ${props.tooltip &&
  css`
    display: flex;
    column-gap: ${spacing(1)};
  `}

  ${props.isRequired &&
  css`
    :after {
      content: '*';
      position: relative;
      font-size: inherit;
      color: ${colors.danger};
      margin-left: 5px;
    }
  `}
`;

function InputLabel({ children, className, htmlFor, isRequired, tooltip }) {
  return (
    <label
      className={className}
      css={labelCss({ isRequired, tooltip })}
      htmlFor={htmlFor}
    >
      {children}
      {tooltip && (
        <Tooltip title={tooltip}>
          <span>
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      )}
    </label>
  );
}

InputLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  /** Indicate input's mandatory status */
  isRequired: PropTypes.bool,
  /** Adds an info tooltip to the input's label. */
  tooltip: PropTypes.string,
};

export default InputLabel;
