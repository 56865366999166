import {
  differenceInCalendarDays,
  format,
  formatDistanceToNowStrict,
  parseISO,
} from 'date-fns';

import { reporter } from '@packages/reporter';
import { formatMaterialTableDate, preParse } from 'utils/dates';

import { filterLabels, OrderStatusLabel } from './constants';

export function dateFormat(date) {
  return formatMaterialTableDate(date);
}

export function timeFormat(date) {
  return format(date, 'HH:mm:ss');
}

export function dateTimeFormat({ date, time24Hour }) {
  const clockFormat = time24Hour ? 'HH:mm' : 'hh:mm a';

  return format(preParse(date), `d MMM yyyy, ${clockFormat}`);
}

/**
 * Return the distance between the given date and now in words.
 *
 * @see
 * https://date-fns.org/docs/formatDistanceToNowStrict
 */
export function distanceToNow(date) {
  return formatDistanceToNowStrict(parseISO(date));
}

/**
 * Return the number of days between a given date and now.
 *
 * @see
 * https://date-fns.org/docs/differenceInDays
 */
export function differenceInDaysToNow(date) {
  return differenceInCalendarDays(new Date(), parseISO(date));
}

/** Compare an object with another and get the differences in properties by values. */
export function checkObjectDiff(object1, object2) {
  const keysChanged = [];

  Object.keys(object1).forEach((key) => {
    if (object2?.[key] !== undefined) {
      if (object1[key] !== object2[key]) {
        keysChanged.push(key);
      }
    }
  });

  return keysChanged;
}

/**
 * Capture an exception event and send it to Sentry along with extra metadata.
 *
 * We don't report 401 and 500 server errors.
 * 401 is usually associated with an expired token, which is a part of a valid flow.
 *
 * @param {*} exception - An exception-like object
 * @param {object} metadata - Extra metadata to be set with the report
 */
export function reportError(exception, metadata) {
  if ([401, 500].includes(metadata?.error?.meta?.statusCode)) {
    return;
  }

  reporter.error(exception, metadata);
}

export function isMobile() {
  const agent = navigator.userAgent ?? navigator.vendor ?? window.opera;
  const MOBILE_REGEX =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;

  if (MOBILE_REGEX.test(agent)) {
    return true;
  }

  return false;
}

/**
 * Prepare an Object that needs to be sent to the backend as a payload.
 * - Trim String values.
 * - Change empty Strings into null.
 */
export function toPayload(payload) {
  const replacer = (key, value) => {
    if (typeof value === 'string') {
      const hasValue = Boolean(value.trim());

      return hasValue ? value.trim() : null;
    }

    return value;
  };

  return JSON.stringify(payload, replacer);
}

/**
 * Extract fields recursively from a form object by using a set of field keys.
 *
 * @example
 * getChangedFields({ a: 'A', b: 'B' }, { a: true }) // Returns { a: 'A' }
 *
 * @param {object} allValues
 * @param {(object|boolean)} fields
 * @returns {object}
 */
export function getChangedFields(allValues, fields) {
  if (fields === true) {
    return allValues;
  }

  return Object.fromEntries(
    Object.keys(fields).map((key) => [
      key,
      getChangedFields(allValues[key], fields[key]),
    ]),
  );
}

/**
 * Parse patient's full address from a patient object
 *
 * @param {object} patient
 * @returns {string}
 */
export function parsePatientFullAddress(patient) {
  return [
    patient.addressLine1,
    patient.addressLine2,
    patient.city,
    patient.postalCode,
    patient.stateOrProvince,
  ]
    .filter((value) => value)
    .join(', ');
}

/**
 * Check if a filters object has active filters in it.
 *
 * @return {bool}
 */
export function areFiltersActive(filters) {
  return Object.values(filters).some((filterGroup) => {
    switch (filterGroup.type) {
      case 'range':
        return Boolean(filterGroup.filters.min || filterGroup.filters.max);

      case 'multi':
        return Boolean(filterGroup.filters.length);

      default:
        return false;
    }
  });
}

/**
 * A utility that takes an array and a value and either adds or removes it from the array.
 *
 * @param {string[]} array
 * @param {string} value
 * @return {array}
 */
export function toggleArray({ array, value }) {
  const newArray = [...array];
  const notInArray = newArray.indexOf(value) === -1;

  if (notInArray) {
    newArray.push(value);
  } else {
    newArray.splice(newArray.indexOf(value), 1);
  }

  return newArray;
}

/**
 * Shapes filters with key and label
 *
 * @example
 * shapeFilters({ language: ['kor'] }) // Returns { language: { key: 'kor', label: 'Korean' }}
 *
 * @param {Object<string, Array<string>>} filters
 * @returns {Object<string, Array<{ key: string, label: string }>>}
 */
export function shapeFilters(filters) {
  const shapedFilters = {};

  Object.entries(filters).forEach(([filterName, filterKeys]) => {
    shapedFilters[filterName] = filterKeys.map((key) => ({
      key,
      label: filterLabels?.[filterName]?.[key] ?? key,
    }));
  });

  return shapedFilters;
}

export function getOrderStatusLabel(status) {
  switch (status) {
    case 'algo_error':
    case 'boundary_condition':
    case 'client_report_algo':
    case 'client_report_timeout':
    case 'client_timeout':
    case 'dry_stick':
      return OrderStatusLabel.ERROR;

    case 'has_results':
      return OrderStatusLabel.HAS_RESULTS;

    case 'client_dry_run':
    case 'client_exam_created':
    case 'client_new_kit':
    case 'new':
      return OrderStatusLabel.PENDING;

    case 'none':
      return OrderStatusLabel.NONE;

    default:
      return '-';
  }
}
