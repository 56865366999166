import PropTypes from 'prop-types';
import MuiTableHead from '@material-ui/core/TableHead';

function TableHead({ children, ...restProps }) {
  return (
    <MuiTableHead component="div" {...restProps}>
      {children}
    </MuiTableHead>
  );
}

TableHead.propTypes = {
  children: PropTypes.node,
};

export default TableHead;
