import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import Hint from '../Hint/Hint';
import InputLabel from '../InputLabel/InputLabel';
import Select from '../Select/Select';

function SelectControl({
  className,
  children,
  id,
  isDisabled,
  isFullWidth,
  isRequired,
  hint,
  label,
  name,
}) {
  const {
    field: { ref, value, onChange },
    fieldState: { error },
  } = useController({
    name,
  });

  const errorMessage = error?.message;
  const isInvalid = Boolean(errorMessage);

  const showHint = Boolean(errorMessage || hint);

  return (
    <div className={className}>
      {label && (
        <InputLabel htmlFor={id} isRequired={isRequired}>
          {label}
        </InputLabel>
      )}
      <Select
        id={id}
        isDisabled={isDisabled}
        isFullWidth={isFullWidth}
        name={name}
        onChange={onChange}
        ref={ref}
        value={value}
      >
        {children}
      </Select>
      {showHint && <Hint isError={isInvalid}>{errorMessage ?? hint}</Hint>}
    </div>
  );
}

SelectControl.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isRequired: PropTypes.bool,
  hint: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default SelectControl;
