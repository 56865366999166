import { lazy, Suspense } from 'react';

function loadable(importFn, Fallback = () => null) {
  const LazyComponent = lazy(importFn);

  return function suspense(props) {
    return (
      <Suspense fallback={<Fallback />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
}

export default loadable;
