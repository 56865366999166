import PropTypes from 'prop-types';
import MuiTableCell from '@material-ui/core-next/TableCell';
import { css } from '@emotion/react';

import { colors, fontSize } from '../../styles/helpers';

const tdCss = css`
  background: red;
  background-color: ${colors.white};
  padding: 18px 8px;
  border-bottom: 0;
  position: relative;
  font-size: ${fontSize.sm};
  height: 80px;

  &:first-of-type {
    padding-left: 16px;
    border-radius: 4px 0 0 4px;
    overflow: hidden;
  }

  &:last-of-type {
    padding-right: 16px;
    border-radius: 0 4px 4px 0;
  }
`;

const accentBorderBaseCss = css`
  :before {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    position: absolute;
  }
`;

const accentBorderCss = {
  danger: css`
    ${accentBorderBaseCss};
    :before {
      background-color: ${colors.danger};
    }
  `,
  primary: css`
    ${accentBorderBaseCss};
    :before {
      background-color: ${colors.primary};
    }
  `,
  success: css`
    ${accentBorderBaseCss};
    :before {
      background-color: ${colors.success};
    }
  `,
};

function Td({ accentBorder, children, className, ...restProps }) {
  return (
    <MuiTableCell
      className={className}
      css={[tdCss, accentBorderCss[accentBorder]]}
      component="div"
      {...restProps}
    >
      {children}
    </MuiTableCell>
  );
}

Td.propTypes = {
  accentBorder: PropTypes.oneOf(['danger', 'primary', 'success']),
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Td;
