import { css } from '@emotion/react';

export default css`
  :root {
    /* -------------------
            Colors
    -------------------- */
    --black: #14263d;
    --blue-gray-100: #f6f9fd;
    --blue-gray-200: #e8eef5;
    --blue-gray-300: #e1eaf3;
    --blue-gray-500: #c8d7e8;
    --danger: var(--danger-500);
    --danger-100: #fff9f9;
    --danger-200: #eedce4;
    --danger-300: #ecc4cd;
    --danger-500: #c23c59;
    --danger-600: #aa2441;
    --danger-700: #8e1e36;
    --gray-100: #f8f8f8;
    --gray-200: #f3f2f2;
    --gray-300: #ededed;
    --gray-400: #d0d0d0;
    --gray-500: #b6b6b6;
    --gray-600: #969b9e;
    --gray-700: #5a6777;
    --primary: var(--primary-400);
    --primary-100: #d9edf0;
    --primary-200: #a8d4dd;
    --primary-300: #4faabb;
    --primary-400: #04859e;
    --primary-500: #176d80;
    --primary-600: #0d5267;
    --primary-700: #0c424e;
    --success: var(--success-500);
    --success-200: #d6eeec;
    --success-300: #a7e0d1;
    --success-500: #22b28c;
    --success-600: #149e7a;
    --success-700: #078a68;
    --text: var(--black);
    --warning: var(--warning-600);
    --warning-200: #f3e7df;
    --warning-600: #e08634;
    --white: #fff;

    /* -------------------
          Font sizes
    -------------------- */
    --font-xs: 12px;
    --font-sm: 14px;
    --font-md: 16px;
    --font-lg: 20px;
    --font-xl: 30px;
    --font-xxl: 40px;

    /* -------------------
            z-index
    -------------------- */
    --z-index-page-loader: 9900;
    --z-index-header: 9950;
    --z-index-menu: 9960;
  }
`;
