import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { colors, spacing } from '../../styles/helpers';

import Loader from '../Loader/Loader';

const defaultLoaderCss = css`
  color: ${colors.primary};
`;

const pageLoaderCss = css`
  position: absolute;
  z-index: var(--z-index-page-loader);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.gray100};
`;

const innerCss = css`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(5)};
  align-items: center;
`;

function PageLoader({ className, loaderComponent }) {
  return (
    <div className={className} css={pageLoaderCss}>
      <div css={innerCss}>
        <div>{loaderComponent ?? <Loader css={defaultLoaderCss} />}</div>
      </div>
    </div>
  );
}

PageLoader.propTypes = {
  className: PropTypes.string,
  /** Replace the default loader animation with a custom component */
  loaderComponent: PropTypes.node,
};

export default PageLoader;
