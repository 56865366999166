import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const svgIconCss = css`
  fill: currentColor;
  width: 1em;
  height: 1em;

  /* font-size is hard-coded, but we could consider not having it,
  allowing it to inherit its parent font-size. */
  font-size: 16px;
`;

function SvgIcon({ children, className, viewBox = '0 0 24 24' }) {
  return (
    <svg className={className} css={svgIconCss} viewBox={viewBox}>
      {children}
    </svg>
  );
}

SvgIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  viewBox: PropTypes.string,
};

export default SvgIcon;
