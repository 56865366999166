import { createSlice } from '@reduxjs/toolkit';

import { shapeFilters } from 'utils/utils';

export const containerKey = 'appConfig';

export const initialState = {
  appType: null,
  backUrl: null,
  clinicianEmail: null,
  clinicianFullName: null,
  email: null,
  fullName: null,
  locale: null,
  partnerDisplayName: null,
  partnerId: null,
  product: null,
  withPatientAddress: null,
  partnerConfig: {},
};

function shapeFiltersByEntity(filtersByEntity) {
  if (!filtersByEntity) {
    return {};
  }

  const shapedFilters = {};

  Object.entries(filtersByEntity).forEach(([entity, filter]) => {
    shapedFilters[entity] = shapeFilters(filter);
  });

  return shapedFilters;
}

const appConfigSlice = createSlice({
  name: containerKey,
  initialState,
  reducers: {
    changeBackUrl(state, { payload }) {
      state.backUrl = payload;
    },
    initAppConfig(state, { payload }) {
      state.appType = payload.appType;
      state.clinicianEmail = payload.clinicianEmail;
      state.clinicianFullName = payload.clinicianFullName;
      state.email = payload.email;
      state.fullName = payload.fullName;
      state.locale = payload.locale;
      state.partnerDisplayName = payload.partnerDisplayName;
      state.partnerId = payload.partnerId;
      state.product = payload.product;
      state.withPatientAddress = payload.withPatientAddress;
      state.partnerConfig = {
        ...payload.partnerConfig,
        filters: shapeFiltersByEntity(payload.partnerConfig?.filters),
      };
    },
  },
});

export const { changeBackUrl, initAppConfig } = appConfigSlice.actions;
export const { reducer } = appConfigSlice;
