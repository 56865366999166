import { css } from '@emotion/react';

const DEFAULT_SCALE_FACTOR = 4;
const DEFAULT_FONT_SIZE = 16;

export const colors = {
  black: 'var(--black)',

  blueGray100: 'var(--blue-gray-100)',
  blueGray200: 'var(--blue-gray-200)',
  blueGray300: 'var(--blue-gray-300)',
  blueGray500: 'var(--blue-gray-500)',

  danger: 'var(--danger)',
  danger100: 'var(--danger-100)',
  danger200: 'var(--danger-200)',
  danger300: 'var(--danger-300)',
  danger500: 'var(--danger-500)',
  danger600: 'var(--danger-600)',
  danger700: 'var(--danger-700)',

  gray200: 'var(--gray-200)',
  gray300: 'var(--gray-300)',
  gray400: 'var(--gray-400)',
  gray500: 'var(--gray-500)',
  gray600: 'var(--gray-600)',
  gray700: 'var(--gray-700)',

  primary: 'var(--primary)',
  primary300: 'var(--primary-300)',
  primary400: 'var(--primary-400)',
  primary500: 'var(--primary-500)',
  primary600: 'var(--primary-600)',
  primary700: 'var(--primary-700)',

  success: 'var(--success)',
  success200: 'var(--success-200)',
  success300: 'var(--success-300)',
  success500: 'var(--success-500)',
  success600: 'var(--success-600)',
  success700: 'var(--success-700)',

  text: 'var(--text)',

  warning: 'var(--warning)',
  warning200: 'var(--warning-200)',
  warning600: 'var(--warning-600)',

  white: 'var(--white)',
};

/**
 * - `xs`: 12px
 * - `sm`: 14px
 * - `md`: 16px
 * - `lg`: 20px
 * - `xl`: 30px
 * - `xxl`: 40px
 */
export const fontSize = {
  xs: 'var(--font-xs)',
  sm: 'var(--font-sm)',
  md: 'var(--font-md)',
  lg: 'var(--font-lg)',
  xl: 'var(--font-xl)',
  xxl: 'var(--font-xxl)',
};

export const formGridCss = css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 5.5%;
  grid-row-gap: 33px;
`;

export const colSpan = (span) => css`
  grid-column: auto / span ${span};
`;

/**
 * Truncate text.
 *
 * @param {number} lineClamp The number of lines to clamp
 */
export const truncateTextCss = (lineClamp) => css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lineClamp};
`;

/**
 * Convert a scale factor into a rem unit.
 * By default, scale of 1 is 4px.
 *
 * @example
 * spacing(4); // 1rem (which is 16px)
 *
 * @param {number} scale - Scale factor
 */
export function spacing(scale) {
  if (typeof scale !== 'number') {
    return '';
  }

  return `${((scale * DEFAULT_SCALE_FACTOR) / DEFAULT_FONT_SIZE) * 1}rem`;
}

export const sentenceCaseCss = css`
  text-transform: lowercase;

  ::first-letter {
    text-transform: uppercase;
  }
`;

export const printNoneCss = css`
  @media print {
    display: none;
  }
`;
