import PropTypes from 'prop-types';
import MuiTableCell from '@material-ui/core-next/TableCell';
import { css } from '@emotion/react';

import { fontSize } from '../../styles/helpers';

const thCss = css`
  font-weight: 400;
  padding: 8px;
  font-size: ${fontSize.xs};
  color: rgba(20, 38, 61, 0.7);
  border: none;

  &:first-of-type {
    padding-left: 16px;
  }

  &:last-of-type {
    padding-right: 16px;
  }
`;

function Th({ children, className, ...restProps }) {
  return (
    <MuiTableCell
      component="div"
      className={className}
      css={thCss}
      {...restProps}
    >
      {children}
    </MuiTableCell>
  );
}

Th.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Th;
