import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';

function createStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
  });

  return store;
}

export default createStore;
