export const PRODUCTS = {
  ACR: 'acr',
  DIP: 'dip',
};

export const LOCALE = {
  UK: 'uk',
  US: 'us',
};

export const APP_TYPE = {
  CALL_CENTER: 'cc',
  MATERNITY: 'maternity',
  PORTAL: 'portal',
};

export const LOCALE_LABEL = {
  [LOCALE.UK]: 'United Kingdom',
  [LOCALE.US]: 'United States',
};

export const ERRORS = {
  DUPLICATE_PHONE_NUMBER: 'DUPLICATE_PHONE_NUMBER',
  INVALID_ADDRESS: 'INVALID_ADDRESS',
  INVALID_INPUT: 'INVALID_INPUT',
  MISSING_PHONE_NUMBER: 'MISSING_PHONE_NUMBER',
};

export const ERROR_LABELS = {
  [ERRORS.DUPLICATE_PHONE_NUMBER]:
    'The phone number is already attached to another patinet.',
  [ERRORS.INVALID_ADDRESS]: 'Invalid address',
  [ERRORS.INVALID_INPUT]: 'Invalid input',
  [ERRORS.MISSING_PHONE_NUMBER]: 'Failed to send SMS. Phone number is missing',
};

export const QueryKeys = {
  Patient: {
    PATIENTS: '[Patient] Patients',
  },
};

export const QUEUE_STATES = {
  CONSENTED: 'consented',
  COOLING_OFF: 'cooling_off',
  NO_RESPONSE: 'no_response',
  OPTED_OUT: 'opted_out',
  PENDING_CONSENT: 'pending_consent',
  PENDING_CONSENT_WITH_KIT: 'pending_consent_with_kit',
  PENDING_FOLLOW_UP_TEST: 'pending_follow_up_test',
  PENDING_KIT_FULFILLMENT: 'pending_kit_fulfillment',
  PENDING_ONBOARDING: 'pending_onboarding',
  PENDING_TEST: 'pending_test',
  PENDING_WARM_UP: 'pending_warm_up',
  TEST_COMPLETED: 'test_completed',
  TEST_ERROR: 'test_error',
  TEST_FAILED: 'test_failed',
};

export const SCRIPT_TYPES = {
  CONSENT: 'consent',
  REMINDER: 'reminder',
  SUPPORT: 'support',
};

export const SCRIPTS = {
  CONSENT: 'consent',
  CONSENT_CALL_FIRST: 'consent-call-first',
  CONSENT_KIT_FIRST: 'consent-kit-first',
  REMINDER: 'reminder',
  SUPPORT: 'support',
};

export const SCRIPT_LABELS = {
  [SCRIPTS.CONSENT]: 'Consent',
  [SCRIPTS.CONSENT_CALL_FIRST]: 'Consent',
  [SCRIPTS.CONSENT_KIT_FIRST]: 'Consent',
  [SCRIPTS.REMINDER]: 'Reminder',
  [SCRIPTS.SUPPORT]: 'Support',
};

export const QUEUE_STATE_LABELS = {
  [QUEUE_STATES.CONSENTED]: 'Consented',
  [QUEUE_STATES.COOLING_OFF]: 'Cooling off',
  [QUEUE_STATES.NO_RESPONSE]: 'No response',
  [QUEUE_STATES.OPTED_OUT]: 'Opted out',
  [QUEUE_STATES.PENDING_CONSENT]: 'Pending consent',
  [QUEUE_STATES.PENDING_CONSENT_WITH_KIT]: 'Pending consent with kit',
  [QUEUE_STATES.PENDING_FOLLOW_UP_TEST]: 'Pending follow up test',
  [QUEUE_STATES.PENDING_KIT_FULFILLMENT]: 'Pending kit fulfillment',
  [QUEUE_STATES.PENDING_ONBOARDING]: 'Pending onboarding',
  [QUEUE_STATES.PENDING_TEST]: 'Pending test',
  [QUEUE_STATES.PENDING_WARM_UP]: 'Pending warmup',
  [QUEUE_STATES.TEST_COMPLETED]: 'Test completed',
  [QUEUE_STATES.TEST_ERROR]: 'Test error',
  [QUEUE_STATES.TEST_FAILED]: 'Test failed',
};

export const ACTIVITY_LOG_TYPES = {
  CONSENT: 'consent',
  EMAIL: 'email',
  FULFILLMENT: 'fulfillment',
  INCOMING_CALL: 'incoming_call',
  OUTGOING_CALL: 'outgoing_call',
  NOTE: 'note',
  PCP: 'pcp',
  SMS: 'sms',
  TEST_RESULTS: 'test_results',
};

export const ACTIVITY_LOG_LABEL = {
  [ACTIVITY_LOG_TYPES.CONSENT]: 'Consent',
  [ACTIVITY_LOG_TYPES.EMAIL]: 'Email',
  [ACTIVITY_LOG_TYPES.FULFILLMENT]: 'Fulfillment',
  [ACTIVITY_LOG_TYPES.INCOMING_CALL]: 'Incoming call',
  [ACTIVITY_LOG_TYPES.OUTGOING_CALL]: 'Outgoing call',
  [ACTIVITY_LOG_TYPES.NOTE]: 'Note',
  [ACTIVITY_LOG_TYPES.PCP]: 'PCP',
  [ACTIVITY_LOG_TYPES.SMS]: 'SMS',
  [ACTIVITY_LOG_TYPES.TEST_RESULTS]: 'Test results',
};

export const SMS_TYPES = {
  CONTACT_DETAILS: 'contact_details',
  DELIVERY: 'delivery',
  DOWNLOAD_APP: 'download_app',
  END_OF_SERVICE: 'end_of_service',
  FINAL_TWO_DAYS: 'final_2_days',
  FOLLOW_UP_1: 'follow_up_1',
  FOLLOW_UP_2: 'follow_up_2',
  FOLLOW_UP_3: 'follow_up_3',
  LAST_CHANCE: 'last_chance',
  OUTREACH_NO_WARMUP: 'outreach_no_warmup',
  OUTREACH_WITH_WARMUP: 'outreach_with_warmup',
  REMINDER_ONE: 'reminder_one',
  REMINDER_TWO: 'reminder_two',
  REMINDER_THREE: 'reminder_three',
  REMINDER_NEAR_END: 'reminder_near_end',
  RESULTS: 'results',
  WARMUP: 'warmup',
};

export const ONBOARDING_FLOW_TYPES = {
  CALL_FIRST: 'callFirst',
  KIT_FIRST: 'kitFirst',
  MISSING_DATA: 'missingData',
};

export const ONBOARDING_FLOW_TYPE_LABELS = {
  [ONBOARDING_FLOW_TYPES.CALL_FIRST]: 'Call flow',
  [ONBOARDING_FLOW_TYPES.KIT_FIRST]: 'Kit flow',
  [ONBOARDING_FLOW_TYPES.MISSING_DATA]: 'Missing data',
};

export const SMS_TYPE_LABEL_BY_LOCALE = {
  [LOCALE.US]: {
    [SMS_TYPES.CONTACT_DETAILS]: 'Contact details',
    [SMS_TYPES.DELIVERY]: 'Delivery',
    [SMS_TYPES.DOWNLOAD_APP]: 'Download app',
    [SMS_TYPES.END_OF_SERVICE]: 'End of service',
    [SMS_TYPES.FINAL_TWO_DAYS]: 'Final 2 days',
    [SMS_TYPES.FOLLOW_UP_1]: 'Follow up 1',
    [SMS_TYPES.FOLLOW_UP_2]: 'Follow up 2',
    [SMS_TYPES.FOLLOW_UP_3]: 'Follow up 3',
    [SMS_TYPES.WARMUP]: 'Warmup',
    [SMS_TYPES.LAST_CHANCE]: 'Final SMS',
    [SMS_TYPES.OUTREACH_NO_WARMUP]: 'Outreach no warmup',
    [SMS_TYPES.OUTREACH_WITH_WARMUP]: 'Outreach with warmup',
  },
  [LOCALE.UK]: {
    [SMS_TYPES.DOWNLOAD_APP]: 'Download app',
    [SMS_TYPES.LAST_CHANCE]: 'Invite to consent',
    [SMS_TYPES.WARMUP]: 'Warmup',
    [SMS_TYPES.REMINDER_ONE]: 'Reminder one',
    [SMS_TYPES.REMINDER_TWO]: 'Reminder two',
    [SMS_TYPES.REMINDER_THREE]: 'Reminder three',
    [SMS_TYPES.REMINDER_NEAR_END]: 'Reminder near end',
    [SMS_TYPES.RESULTS]: 'Results',
  },
};

export const SEND_SMS_STATUSES = {
  ERROR: 'error',
  PENDING: 'pending',
  SCHEDULED: 'scheduled',
  SENT: 'sent',
};

export const TEST_RESULTS_STATUSES = {
  AFTER_PCP: 'after_pcp',
  ALGO_ERROR: 'algo_error',
  BOUNDARY_CONDITION: 'boundary_condition',
  CLIENT_PENDING_PCP: 'client_pending_pcp',
  CLIENT_TIMEOUT: 'client_timeout',
  HAS_RESULT: 'has_results',
};

export const PCP_STATUS = {
  CONFIRMED: 'confirmed',
  NO_PCP: 'noPcp',
  OPT_OUT: 'optOut',
  PENDING: 'pending',
  UNCONFIRMED: 'unconfirmed',
};

export const PCP_STATUS_LABEL = {
  [PCP_STATUS.CONFIRMED]: 'Confirmed',
  [PCP_STATUS.NO_PCP]: 'No PCP',
  [PCP_STATUS.OPT_OUT]: 'Opt out',
  [PCP_STATUS.PENDING]: 'Pending',
  [PCP_STATUS.UNCONFIRMED]: 'Unconfirmed',
};

export const FULFILLMENT_TYPES = {
  ADDRESS_VALIDATION: 'address_validation',
  DELIVERY_LETTER: 'delivery_letter',
  LAST_CHANCE_LETTER: 'last_chance_letter',
  KIT: 'kit',
  RESULTS_LETTER: 'results_letter',
  WARM_UP_LETTER: 'warm_up_letter',
  WELCOME_LETTER: 'welcome_letter',
};

export const FULFILLMENT_TYPE_LABELS = {
  [FULFILLMENT_TYPES.ADDRESS_VALIDATION]: 'Address validation by Spire',
  [FULFILLMENT_TYPES.DELIVERY_LETTER]: 'Delivery letter',
  [FULFILLMENT_TYPES.LAST_CHANCE_LETTER]: 'Last chance letter',
  [FULFILLMENT_TYPES.KIT]: 'Kit',
  [FULFILLMENT_TYPES.RESULTS_LETTER]: 'Results letter',
  [FULFILLMENT_TYPES.WARM_UP_LETTER]: 'Warmup letter',
  [FULFILLMENT_TYPES.WELCOME_LETTER]: 'Welcome letter',
};

export const FULFILLMENT_STATUSES = {
  ACCEPTED: 'accepted',
  ARRIVED: 'arrived',
  CANCELED: 'canceled',
  ERROR: 'error',
  PENDING: 'pending',
  SENT: 'sent',
  SHIPPED: 'shipped',
};

export const FULFILLMENT_STATUS_LABELS = {
  [FULFILLMENT_STATUSES.ACCEPTED]: 'accepted',
  [FULFILLMENT_STATUSES.ARRIVED]: 'arrived',
  [FULFILLMENT_STATUSES.CANCELED]: 'canceled',
  [FULFILLMENT_STATUSES.ERROR]: 'error',
  [FULFILLMENT_STATUSES.PENDING]: 'pending',
  [FULFILLMENT_STATUSES.SENT]: 'sent to fulfillment',
  [FULFILLMENT_STATUSES.SHIPPED]: 'shipped',
};

export const EMAIL_TYPES = {
  DELIVERY: 'delivery',
  FINAL_2_DAYS: 'final_2_days',
  FOLLOW_UP_1: 'follow_up_1',
  WARM_UP: 'warm_up',
};

export const EMAIL_TYPE_LABELS = {
  [EMAIL_TYPES.DELIVERY]: 'Delivery',
  [EMAIL_TYPES.FINAL_2_DAYS]: 'Final two days',
  [EMAIL_TYPES.FOLLOW_UP_1]: 'Follow up 1',
  [EMAIL_TYPES.WARM_UP]: 'Warmup',
};

export const CONSENT_STATUSES = {
  CONSENTED: 'consented',
  DISSENTED: 'dissented',
  INCOMPATIBLE: 'incompatible',
};

export const CALL_DISPOSITION = {
  ANSWERED: 'answered',
  UNANSWERED: 'unanswered',
};

export const CALL_DISPOSITION_LABELS = {
  [CALL_DISPOSITION.ANSWERED]: 'Answered',
  [CALL_DISPOSITION.UNANSWERED]: 'Unanswered',
};

export const OUTCOME = {
  CALL_LATER: 'callLater',
  CONSENTED: 'consented',
  DISSENTED: 'dissented',
  INCOMPATIBLE: 'incompatible',
  INDETERMINATE: 'indeterminate',
  NO_VOICEMAIL_LEFT: 'noVoicemailLeft',
  NOTE_ONLY: 'noteOnly',
  REMINDER_COMPLETED: 'reminderCompleted',
  SUPPORT_COMPLETED: 'supportCompleted',
  UNREACHABLE_ANSWERED: 'unreachableAnswered',
  UNREACHABLE_UNANSWERED: 'unreachableUnanswered',
  VOICEMAIL_LEFT: 'voicemailLeft',
};

export const OUTCOME_LABELS = {
  [OUTCOME.CALL_LATER]: "Can't talk - Call later",
  [OUTCOME.CONSENTED]: 'Consented - Interested in the test',
  [OUTCOME.DISSENTED]: 'Opt out - Declined',
  [OUTCOME.INCOMPATIBLE]: 'Ineligible',
  [OUTCOME.INDETERMINATE]: 'Indeterminate',
  [OUTCOME.NO_VOICEMAIL_LEFT]: 'No VM left',
  [OUTCOME.NOTE_ONLY]: 'Note only',
  [OUTCOME.REMINDER_COMPLETED]: 'Reminder call completed',
  [OUTCOME.SUPPORT_COMPLETED]: 'Support call completed',
  [OUTCOME.UNREACHABLE_ANSWERED]: 'Answered - Unreachable',
  [OUTCOME.UNREACHABLE_UNANSWERED]: 'No answer - Unreachable',
  [OUTCOME.VOICEMAIL_LEFT]: 'Left VM',
};

export const ELABORATION_LABELS = {
  ALREADY_DID_ACR_TEST: 'Already did an ACR test in the past 12 months',
  ALREADY_SEEING_KIDNEY_DOCTOR: 'Already seeing a kidney doctor',
  ALREADY_TESTED_NOW_DECLINES:
    'Patient tried the test but now declines service',
  AT_WORK: 'At work',
  BRING_SAMPLE: 'Would rather bring a sample to surgery',
  BUSY: 'Busy',
  BUSY_TONE: 'Busy tone',
  CANT_GET_THROUGH: "Can't get through to the patient",
  CARE_HOME_RESIDENT: 'Care home resident',
  CLINICALLY_INELIGIBLE:
    'Clinically ineligible (e.g. pregnant, not diabetic, on dialysis, End of life, catheter-in-situ, unable to provide a sample, incontinent)',
  COMPLETED_TEST_WANTS_TO_BE_REMOVED:
    'Patient completed the test and wants details removed',
  COUNTRY_PRACTICE_HOSPITAL:
    'Out of country / No longer at practice / In hospital',
  DECEASED: 'Deceased',
  DISENROLLED: 'Disenrolled from plan',
  DOES_NOT_SPEAK_ENGLISH: 'Does not speak English',
  DOES_NOT_SPEAK_ENGLISH_SPANISH: 'Does not speak English or Spanish',
  DONT_CALL_HUNG_UP: 'Do not call / Hung up',
  DRIVING: 'Driving',
  GAVE_SAMPLE_HAVE_APPOINTMENT:
    'Just gave a sample / Already have an appointment',
  INCOMPATIBLE_PHONE: 'Incompatible phone',
  LACK_OF_CONFIDENCE: 'Lack of confidence with apps and self testing tests',
  NEED_TO_SPEAK_WITH_PCP: 'Need to speak with PCP',
  NO_SMARTPHONE_ACCESS: "Don't have access to a smartphone",
  NO_RING_DEAD_AIR: 'Does not ring / Dead air',
  NOT_INTERESTED: 'Not interested',
  NO_VOICEMAIL: 'Answering machine did not come on',
  NO_VOICEMAIL_OPTION: 'No voice mail option',
  NOT_IN_SERVICE_DISCONNECTED: 'Number no longer in service / disconnected',
  OPTED_OUT_BY_GP: 'Opted out via GP',
  OPTED_OUT_BY_SMS: 'Opted out via SMS',
  OTHER: 'Other',
  OUT_OF_COUNTRY_AWAY_FROM_HOME: 'Out of the country / Away from home',
  PATIENT_DETAILS_INCORRECT: 'Patient Details incorrect',
  PRIVACY_CONCERNS: 'Privacy concerns',
  SECURITY_CONCERNS: 'Concerned about information security',
  TROUBLE_WITH_APP:
    'Not good with tech or apps / Had trouble downloading the app',
  UNABLE_READ_OR_WRITE: 'Unable to read or write',
  VOICEMAIL_FULL: 'Answering machine full',
  VOICEMAIL_NOT_NECESSARY: 'VM not necessary',
  VOICEMAIL_NOT_SET_UP: 'Vm box not set up',
  WANT_MORE_INFO: 'Want to get more info',
  WRONG_NAME_IN_VOICEMAIL: 'Wrong name in vm greeting',
  WRONG_NUMBER: 'Wrong number',
  WRONG_OR_DISCONNECTED_NUMBERS:
    'All numbers shared with us are either wrong or disconnected',
};

export const OUTCOME_HINT = {
  BAD_NUMBER: 'Bad number - No need to use this number again',
  CANNOT_BE_REACHED_BY_PHONE: 'Member cannot be reached by phone',
  DOES_NOT_MEET_INCLUSION_CRITERIA:
    'Members who do not meet the service’s inclusion criteria',
  INTEREST_IN_DOING_TEST: 'Member shows interest in doing the test',
  MEET_INCLUSION_CRITERIA:
    "Members who meet our initial inclusion criteria but don't want to take part",

  NEED_ANOTHER_CONSENT_CALL:
    "Member didn't say if he will do or won't do the test - need another consent call",
  NO_CALL_MADE: 'No call was made - add a note to the activity log',
  REMINDED_TO_DO_TEST: 'Member was reminded to do the test',
  REQUESTED_SCHEDULE: 'Member requested to schedule a call at a later time',
};

export const OUTCOME_OPTION = {
  ALREADY_DONE_ACR: 'alreadyDoneAcr',
  ANSWERING_MACHINE_FULL: 'answeringMachineFull',
  BRING_SAMPLE: 'bringSample',
  BUSY_TONE: 'busyTone',
  CALL_QUEUE_AFFECTED: 'callQueueAffected',
  CALL_QUEUE_NOT_AFFECTED: 'callQueueNotAffected',
  CARE_HOME_RESIDENT: 'careHomeResident',
  CLINICALLY_INELIGIBLE: 'clinicallyIneligible',
  PRIVACY_CONCERNS: 'privacyConcerns',
  DONT_SEND_KIT: 'dontSendKit',
  SEND_KIT: 'sendKit',
  DECEASED: 'deceased',
  DISENROLLED: 'Disenrolled',
  DIDNT_LEAVE_VOICEMAIL: 'didntLeaveVoicemail',
  DONT_CALL: 'dontCall',
  DONT_HAVE_DIABETES: 'dontHaveDiabetes',
  DONT_OWN_SMARTPHONE: 'dontOwnSmartphone',
  DONT_NEED_SERVICE: 'dontNeedService',
  DONT_SPEAK_ENGLISH: 'dontSpeakEnglish',
  DONT_SPEAK_LANGUAGES: 'dontSpeakLanguages',
  LACK_OF_CONFIDENCE: 'lackOfConfidence',
  NO_DIAL_TONE: 'noDialTone',
  NO_RESPONSE: 'noResponse',
  NO_VOICEMAIL_OPTION: 'noVoicemailOption',
  NOT_AVAILABLE: 'notAvailable',
  NOT_INTERESTED: 'notInterested',
  NOT_TECHY: 'notTechy',
  NUMBER_NOT_IN_SERVICE: 'numberNotInService',
  OPT_OUT_GP: 'optOutGp',
  OTHER_NOTE: 'otherNote',
  OTHER: 'other',
  WRONG_NUMBER: 'wrongNumber',
  VOICEMAIL_DIDNT_WORK: 'voicemailDidntWork',
  VOICEMAIL_LEFT: 'voicemailLeft',
  VOICEMAIL_NOT_SET: 'voicemailNotSet',
  VOICEMAIL_WRONG_NAME: 'voicemailWrongName',
};

export const OUTCOME_OPTION_LABEL = {
  [OUTCOME_OPTION.ALREADY_DONE_ACR]: 'Has already done an ACR test',
  [OUTCOME_OPTION.ANSWERING_MACHINE_FULL]: 'Answering machine full',
  [OUTCOME_OPTION.BRING_SAMPLE]: 'Would rather bring a sample to surgery',
  [OUTCOME_OPTION.BUSY_TONE]: 'Busy tone',
  [OUTCOME_OPTION.CALL_QUEUE_AFFECTED]: 'Affects the call queue',
  [OUTCOME_OPTION.CALL_QUEUE_NOT_AFFECTED]: 'Does not affect the call queue',
  [OUTCOME_OPTION.CARE_HOME_RESIDENT]: 'Care home resident',
  [OUTCOME_OPTION.CLINICALLY_INELIGIBLE]:
    'Clinically ineligible (e.g. pregnant, not diabetic, on dialysis, End of life, catheter-in-situ, unable to provide a sample, incontinent)',
  [OUTCOME_OPTION.PRIVACY_CONCERNS]: 'Privacy concerns',
  [OUTCOME_OPTION.DONT_SEND_KIT]: "Don't send another kit",
  [OUTCOME_OPTION.SEND_KIT]: 'Send a kit',
  [OUTCOME_OPTION.DECEASED]: 'Deceased',
  [OUTCOME_OPTION.DISENROLLED]: 'Disenrolled from plan',
  [OUTCOME_OPTION.DIDNT_LEAVE_VOICEMAIL]: "Didn't leave a voicemail",
  [OUTCOME_OPTION.DONT_CALL]: 'Do not call / hung up',
  [OUTCOME_OPTION.DONT_HAVE_DIABETES]: "Don't have diabetes",
  [OUTCOME_OPTION.DONT_OWN_SMARTPHONE]: "Don't have access to a smartphone",
  [OUTCOME_OPTION.DONT_NEED_SERVICE]:
    'Going regularly to the clinic / just gave a sample / already have an appointment',
  [OUTCOME_OPTION.DONT_SPEAK_ENGLISH]: 'Does not speak English',
  [OUTCOME_OPTION.DONT_SPEAK_LANGUAGES]: 'Do not speak English or Spanish',
  [OUTCOME_OPTION.LACK_OF_CONFIDENCE]:
    'Lack of confidence with apps and self testing tests',
  [OUTCOME_OPTION.NO_DIAL_TONE]: 'Does not ring/dead air',
  [OUTCOME_OPTION.NO_VOICEMAIL_OPTION]: 'No voice mail option',
  [OUTCOME_OPTION.NOT_AVAILABLE]:
    'Out of country / No longer at practice / In hospital',
  [OUTCOME_OPTION.NOT_INTERESTED]: 'Not interested (elaborate in notes)',
  [OUTCOME_OPTION.NOT_TECHY]:
    'Not good with tech / apps / had trouble downloading the app',
  [OUTCOME_OPTION.NUMBER_NOT_IN_SERVICE]:
    'Number no longer in service / disconnected',
  [OUTCOME_OPTION.OPT_OUT_GP]: 'Opted out via GP',
  [OUTCOME_OPTION.OTHER_NOTE]: 'Other (elaborate in note)',
  [OUTCOME_OPTION.OTHER]: 'Other',
  [OUTCOME_OPTION.WRONG_NUMBER]: 'Wrong number',
  [OUTCOME_OPTION.VOICEMAIL_DIDNT_WORK]: 'Answering machine did not come on',
  [OUTCOME_OPTION.VOICEMAIL_LEFT]: 'Voicemail left',
  [OUTCOME_OPTION.VOICEMAIL_NOT_SET]: 'Vm box not set up',
  [OUTCOME_OPTION.VOICEMAIL_WRONG_NAME]: 'Wrong name in vm greeting',
};

export const states = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'American Samoa', abbreviation: 'AS' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District of Columbia', abbreviation: 'DC' },
  { name: 'Federated States of Micronesia', abbreviation: 'FM' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Guam', abbreviation: 'GU' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Marshall Islands', abbreviation: 'MH' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Northern Mariana Islands', abbreviation: 'MP' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Palau', abbreviation: 'PW' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virgin Islands', abbreviation: 'VI' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

export const COMMUNICATION_PREFERENCE_STATUS = {
  ALLOWED: 'allowed',
  DEFAULT: 'default',
  DENIED: 'denied',
};

export const COMMUNICATION_PREFERENCE_STATUS_LABEL = {
  [COMMUNICATION_PREFERENCE_STATUS.ALLOWED]: 'Allowed',
  [COMMUNICATION_PREFERENCE_STATUS.DEFAULT]: 'Default',
  [COMMUNICATION_PREFERENCE_STATUS.DENIED]: 'Denied',
};

export const COMMUNICATION_PREFERENCE = {
  CALL: 'call',
  EMAIL: 'email',
  LETTER: 'letter',
  SMS: 'sms',
};

export const COMMUNICATION_PREFERENCE_LABEL = {
  [COMMUNICATION_PREFERENCE.CALL]: 'Calls',
  [COMMUNICATION_PREFERENCE.EMAIL]: 'Emails',
  [COMMUNICATION_PREFERENCE.LETTER]: 'Letters',
  [COMMUNICATION_PREFERENCE.SMS]: 'SMS',
};

export const TRACK_SCREEN_TYPE = {
  CALLS: 'Calls View',
  EDIT_PATIENT: 'Edit Patient Info',
  EDIT_PCP: 'Edit PCP Info',
  PATIENT: 'Patient Page',
  PATIENT_LIST: 'Patient List',
  PCP_CENTER: 'PCP Center',
  SCRIPT: 'Script',
};

export const PATIENT_INFO_FIELD = {
  ADDRESS_LINE_1: 'addressLine1',
  ADDRESS_LINE_2: 'addressLine2',
  CITY: 'city',
  COUNTRY: 'country',
  FIRST_NAME: 'firstName',
  HEALTH_ID: 'healthId',
  LANDLINE: 'landline',
  LAST_NAME: 'lastName',
  MOBILE: 'mobile',
  PHONE_NUMBER: 'phoneNumber',
  POSTAL_CODE: 'postalCode',
  STATE: 'state',
  ZIP_CODE: 'zipCode',
};

export const PATIENT_INFO_FIELD_LABEL = {
  [PATIENT_INFO_FIELD.ADDRESS_LINE_1]: 'Address line 1',
  [PATIENT_INFO_FIELD.ADDRESS_LINE_2]: 'Address line 2',
  [PATIENT_INFO_FIELD.CITY]: 'City',
  [PATIENT_INFO_FIELD.COUNTRY]: 'Country',
  [PATIENT_INFO_FIELD.FIRST_NAME]: 'First name',
  [PATIENT_INFO_FIELD.HEALTH_ID]: 'ID',
  [PATIENT_INFO_FIELD.LANDLINE]: 'Home phone number',
  [PATIENT_INFO_FIELD.LAST_NAME]: 'Last name',
  [PATIENT_INFO_FIELD.PHONE_NUMBER]: 'Mobile phone number',
  [PATIENT_INFO_FIELD.POSTAL_CODE]: 'Postal code',
  [PATIENT_INFO_FIELD.STATE]: 'State',
  [PATIENT_INFO_FIELD.ZIP_CODE]: 'ZIP code',
};

export const LanguageCodes = {
  BEN: 'ben',
  ENG: 'eng',
  FRA: 'fra',
  DEU: 'deu',
  HIN: 'hin',
  IND: 'ind',
  JPN: 'jpn',
  KOR: 'kor',
  CMN: 'cmn',
  MAR: 'mar',
  POR: 'por',
  RUS: 'rus',
  SPA: 'spa',
  ARB: 'arb',
  TAM: 'tam',
  TEL: 'tel',
  TUR: 'tur',
  URD: 'urd',
  WUU: 'wuu',
  YUE: 'yue',
  UNSPECIFIED: 'unspecified',
};

export const LanguageLabels = {
  [LanguageCodes.BEN]: 'Bengali',
  [LanguageCodes.ENG]: 'English',
  [LanguageCodes.FRA]: 'French',
  [LanguageCodes.DEU]: 'German',
  [LanguageCodes.HIN]: 'Hindi',
  [LanguageCodes.IND]: 'Indonesian',
  [LanguageCodes.JPN]: 'Japanese',
  [LanguageCodes.KOR]: 'Korean',
  [LanguageCodes.CMN]: 'Mandarin Chinese',
  [LanguageCodes.MAR]: 'Marathi',
  [LanguageCodes.POR]: 'Portuguese',
  [LanguageCodes.RUS]: 'Russian',
  [LanguageCodes.SPA]: 'Spanish',
  [LanguageCodes.ARB]: 'Standard Arabic',
  [LanguageCodes.TAM]: 'Tamil',
  [LanguageCodes.TEL]: 'Telugu',
  [LanguageCodes.TUR]: 'Turkish',
  [LanguageCodes.URD]: 'Urdu',
  [LanguageCodes.WUU]: 'Wu Chinese',
  [LanguageCodes.YUE]: 'Yue Chinese',
  [LanguageCodes.UNSPECIFIED]: 'Unspecified',
};

export const languages = [
  { label: LanguageLabels[LanguageCodes.BEN], code: LanguageCodes.BEN },
  { label: LanguageLabels[LanguageCodes.ENG], code: LanguageCodes.ENG },
  { label: LanguageLabels[LanguageCodes.FRA], code: LanguageCodes.FRA },
  { label: LanguageLabels[LanguageCodes.DEU], code: LanguageCodes.DEU },
  { label: LanguageLabels[LanguageCodes.HIN], code: LanguageCodes.HIN },
  { label: LanguageLabels[LanguageCodes.IND], code: LanguageCodes.IND },
  { label: LanguageLabels[LanguageCodes.JPN], code: LanguageCodes.JPN },
  { label: LanguageLabels[LanguageCodes.KOR], code: LanguageCodes.KOR },
  { label: LanguageLabels[LanguageCodes.CMN], code: LanguageCodes.CMN },
  { label: LanguageLabels[LanguageCodes.MAR], code: LanguageCodes.MAR },
  {
    label: LanguageLabels[LanguageCodes.POR],
    code: LanguageCodes.POR,
    common: true,
  },
  {
    label: LanguageLabels[LanguageCodes.RUS],
    code: LanguageCodes.RUS,
    common: true,
  },
  {
    label: LanguageLabels[LanguageCodes.SPA],
    code: LanguageCodes.SPA,
    common: true,
  },
  { label: LanguageLabels[LanguageCodes.ARB], code: LanguageCodes.ARB },
  { label: LanguageLabels[LanguageCodes.TAM], code: LanguageCodes.TAM },
  { label: LanguageLabels[LanguageCodes.TEL], code: LanguageCodes.TEL },
  { label: LanguageLabels[LanguageCodes.TUR], code: LanguageCodes.TUR },
  { label: LanguageLabels[LanguageCodes.URD], code: LanguageCodes.URD },
  { label: LanguageLabels[LanguageCodes.WUU], code: LanguageCodes.WUU },
  { label: LanguageLabels[LanguageCodes.YUE], code: LanguageCodes.YUE },
  {
    label: LanguageLabels[LanguageCodes.UNSPECIFIED],
    code: LanguageCodes.UNSPECIFIED,
  },
];

export const DIRECTION_TYPE = {
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
};

export const scriptIdToCallType = {
  [SCRIPTS.CONSENT]: 'consent',
  [SCRIPTS.CONSENT_CALL_FIRST]: 'consent',
  [SCRIPTS.CONSENT_KIT_FIRST]: 'consent',
  [SCRIPTS.REMINDER]: 'reminder',
  [SCRIPTS.SUPPORT]: 'support',
};

export const filterLabels = {
  queueState: QUEUE_STATE_LABELS,
  language: LanguageLabels,
};

export const FilterGroupLabels = {
  clinicName: 'Clinic name',
  cohortName: 'Cohort name',
  language: 'Language',
};

export const OrderTypes = {
  BLOOD_PRESSURE: 'blood_pressure',
  URINE: 'urine',
};

export const OrderTypeLabels = {
  [OrderTypes.BLOOD_PRESSURE]: 'Blood pressure',
  [OrderTypes.URINE]: 'Urine',
};

export const OrderStatusLabel = {
  ERROR: 'Test error',
  HAS_RESULTS: 'Has results',
  NONE: 'None',
  PENDING: 'Pending',
};

export const BloodPressureResults = {
  DIA: 'DIA',
  PULSE: 'PULSE',
  SYS: 'SYS',
};

export const BloodPressureResultLabels = {
  [BloodPressureResults.DIA]: 'Diastolic',
  [BloodPressureResults.PULSE]: 'Pulse',
  [BloodPressureResults.SYS]: 'Systolic',
};

export const OrderAccentColor = {
  [OrderStatusLabel.ERROR]: 'danger',
  [OrderStatusLabel.HAS_RESULTS]: 'primary',
};
