import { css } from '@emotion/react';
import { colors } from '@packages/ui';
import PropTypes from 'prop-types';

const cardBodyCss = (props) => css`
  padding: 24px;

  ${!props.flushBorder &&
  css`
    &:not(:last-of-type) {
      border-bottom: 1px solid ${colors.gray500};
    }
  `}
`;

function CardBody({ children, className, flushBorder }) {
  return (
    <div className={className} css={cardBodyCss({ flushBorder })}>
      {children}
    </div>
  );
}

CardBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** Remove divider */
  flushBorder: PropTypes.bool,
};

export default CardBody;
